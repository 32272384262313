import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import useResizeScreen from "../utils/resize";
import IconCheck from "../images/icons/interface/check.svg";
import Divider1 from "../images/dividers/divider-1.svg";
import IconHome from "../images/icons/theme/home/building.svg";
import IconChartLine from "../images/icons/theme/shopping/chart-line-1.svg";
import IconIphonePayment from "../images/icons/theme/devices/iphone-x-payment.svg";
import IconBitcoin from "../images/icons/theme/shopping/bitcoin.svg";
import IconArrowLong from "../images/icons/interface/arrow-long.svg";
import IconTailored from "../images/icons/theme/tools/IconService1.svg";
import IconSun from "../images/icons/theme/weather/sun.svg";
import {GatsbyImage} from "gatsby-plugin-image";
// import {FintechJsonLd} from "../components/jsonld/FintechJsonLd2";

const FintechDesignPage = ({location: {pathname}, data}) => {
    const {isMobile, isTablet} = useResizeScreen();

    return (
        <Layout
            meta={{
                path: pathname
            }}
            footerBgClass="bg-primary-3"
        >
            {/* <FintechJsonLd/> */}
            <div className="landing-page-1">
                <SectionFirstScreen
                    isMobile={isMobile}
                    isTablet={isTablet}
                    data={data}
                />
                <SectionEighthScreen isMobile={isMobile}/>
                <SectionSecondScreen data={data}/>
                <SectionSixthScreen isMobile={isMobile} isTablet={isTablet}/>
                <SectionFourthScreen
                    isMobile={isMobile}
                    isTablet={isTablet}
                    data={data}
                />
                <SectionFifthScreen isMobile={isMobile}/>

            </div>
        </Layout>
    );
};

const SectionFirstScreen = ({isTablet, data}) => {

    return (
        <section className="section-first o-hidden py-5">
            <div className="container">
                <div className="row align-items-center min-vh-60">
                    <div className="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                        {!isTablet && (
            <span className="alert alert-primary">
                short explanation why to invest, a moto
              </span>
                        )}
                        <h1 className="display-4">
                            Invest:
                            <br/> Lorem ipsum dolor sit amet, consectetur
                        </h1>
                        <div className="my-5">
                            <p className="lead">
                                Let us <span>drive</span> your investments to certainty
                            </p>
                        </div>
                        <Link
                            to="/#"
                            id="freeconsultation"
                            className="btn btn-lg btn-primary"
                        >
                            
                            Get a Free Estimate
                        </Link>
                    </div>
                    <div className="col-lg-5 text-center ">
                        {/* <div className="min-vw-50">
                            <GatsbyImage objectFit="contain"
                                         alt={"Image"}
                                         style={{width: 800}}
                                         image={imageFirstSection}
                                         className=" " loading="eager"/>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionEighthScreen = ({isMobile}) => {
    const settings = {
        autoplay: false,
        arrows: false,
        dots: false,
        infinite: true,
        speed: 1750,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true
    };

    return (
        <section className="eight-section bg-primary-alt has-divider">
            <div className="divider">
                <Divider1/>
            </div>

            <div className="container">
                <div className="content">
                    <h3 className="h2">Your Trusted Partner</h3>
                    <p className="lead">
                        {!isMobile
                            ? `Regardless of what kind of product you work on, we've got you covered:`
                            : "We can help with the following:"}
                    </p>
                </div>
                <div className="row cards">
                    <div className="col-md-6 col-lg-3">
                        <div className="card card--common card-body">
                            <div className="card--front">
                                <div className="icon-round bg-primary mb-4">
                                    <IconIphonePayment className="icon icon-lg align-items-left "/>
                                </div>
                                <h4>Morbi lectus risus, iaculis</h4>
                                <IconArrowLong className="arrow"/>
                            </div>
                            <div className="card--back">
                                <h4>Morbi lectus risus, iaculis</h4>
                                <p>
                                Proin ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Fusce ac turpis quis ligula lacinia aliquet
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card card--common card-body">
                            <div className="card--front">
                                <div className="icon-round bg-primary mb-4">
                                    <IconHome className="icon icon-lg align-items-left "/>
                                </div>
                                <h4>Morbi lectus risus, iaculis</h4>
                                <IconArrowLong className="arrow"/>
                            </div>
                            <div className="card--back">
                                <h4>Morbi lectus risus, iaculis</h4>
                                <p>
                                Proin ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Fusce ac turpis quis ligula lacinia aliquet
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card card--common card-body">
                            <div className="card--front">
                                <div className="icon-round bg-primary mb-4">
                                    <IconChartLine className="icon icon-lg align-items-left "/>
                                </div>
                                <h4>Strategic & Analytical Tools</h4>
                                <IconArrowLong className="arrow"/>
                            </div>
                            <div className="card--back">
                                <h4>Strategic & Analytical Tools</h4>
                                <p>
                                Proin ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Fusce ac turpis quis ligula lacinia aliquet
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card card--common card-body">
                            <div className="card--front">
                                <div className="icon-round bg-primary mb-4">
                                    <IconBitcoin className="icon icon-lg align-items-left "/>
                                </div>
                                <h4>Morbi lectus risus, iaculis</h4>
                                <IconArrowLong className="arrow"/>
                            </div>
                            <div className="card--back">
                                <h4>Morbi lectus risus, iaculis</h4>
                                <p>
                                Proin ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Fusce ac turpis quis ligula lacinia aliquet
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionSecondScreen = ({data}) => {
    const imageSecondSection = data.imageSecondSection.childImageSharp.gatsbyImageData;

    return (
        <section className="second-section">
            <div className="container">
                <h3 className="h2">Make a financial tool usable</h3>
                <p className="mb-4 lead">
                maybe a graphic dashboard relevant to that ↓↓↓
                </p>
                <div className="decoration">
                    <GatsbyImage objectFit="contain" loading={"lazy"} image={imageSecondSection} alt="Image"
                                 className="w-100"/>
                </div>
            </div>
        </section>
    );
};


const SectionFourthScreen = ({isMobile, isTablet, data}) => {
    const imageFinanceBoring = data.imageFinanceBoring.childImageSharp.gatsbyImageData;
    // const imageFinanceBoringMobile =
    //     data.imageFinanceBoringMobile.childImageSharp.gatsbyImageData;
    const imageDontBeLeftBehind =
        data.imageDontBeLeftBehind.childImageSharp.gatsbyImageData;
    const imageDontBeLeftBehindMobile =
        data.imageDontBeLeftBehindMobile.childImageSharp.gatsbyImageData;

    // const imageSourceFinanceBoring = isMobile
    //     ? imageFinanceBoringMobile
    //     : imageFinanceBoring;
    const imageSourceStayAhead = isMobile
        ? imageDontBeLeftBehindMobile
        : imageDontBeLeftBehind;

    return (
        <section className="o-hidden fourth-section">
            <div className="top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 content lead">
                            <h3 className="h2">Make finance exciting</h3>
                            <p>
                            Praesent blandit dolor. Sed non quam. In vel mi sit amet augue congue elementum.
                            </p>
                            <p>
                            Proin quam. Etiam ultrices. Suspendisse in justo eu magna luctus suscipit. Sed lectus. Integer euismod lacus luctus magna. Quisque cursus, metus vitae pharetra auctor, sem massa mattis sem, at interdum magna augue eget diam. 
                            </p>
                            <Link
                                to="#"
                                className={`btn btn-lg mt-3 ${
                                    !isMobile ? "btn-primary" : "btn-primary"
                                }`}
                            >
                                Contact Us
                            </Link>
                        </div>
                        <div className="col-md-6">
                            <GatsbyImage objectFit="contain" alt={"Make finance exciting"}
                                         style={{display: "inline-block"}}
                                         image={imageFinanceBoring} loading={"lazy"}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-6 order-1 order-md-0 image">
                            <GatsbyImage alt={"Always stay ahead"}
                                         style={{display: "inline-block"}}
                                         image={imageSourceStayAhead} loading={"lazy"}/>
                        </div>
                        <div className="col-md-6 content lead">
                            <h3 className="h2">Always stay ahead</h3>
                            <p>
                            Praesent blandit dolor. Sed non quam. In vel mi sit amet augue congue elementum.
                            </p>
                            <p>
                            Praesent blandit dolor. Sed non quam. In vel mi sit amet augue congue elementum.
                            </p>

                            <p>
                                Act now, and we will help you ensure that your financial tools
                                are user-friendly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionFifthScreen = ({}) => (
    <section className="fifth-section bg-primary-alt has-divider">
        <div className="divider">
            <Divider1/>
        </div>

        <div className="decoration-wrapper">
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-10 mx-auto">
                    <div className="content lead">
                        <h3 className="h2">Be ahead</h3>
                        <p>
                        Praesent blandit dolor. Sed non quam. In vel mi sit amet augue congue elementum.
                        </p>
                    </div>

                    <div className="container pt-3 pb-3">
                        <div className="row lead">
                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Praesent blandit dolor. Sed non quam. In vel mi sit amet augue congue elementum. </p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Praesent blandit dolor.</p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Praesent blandit dolor.Praesent blandit dolor.</p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Praesent blandit dolor.Praesent blandit dolor.</p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Praesent blandit dolor.Praesent blandit dolor.</p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Praesent blandit dolor.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const SectionSixthScreen = ({isMobile, isTablet}) => (
    <section className="sixth-section bg-primary-3 py-4 py-lg-7">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 text-light caption">
                    <div>
                        <h3 className="h2 pb-3 pb-lg-7">
                            Benefits that you will enjoy while working with us:
                        </h3>
                        {!isTablet && (
                            <Link
                                to="/#"
                                id="freeconsultation"
                                className="btn btn-lg btn-primary"
                            >
                                Contact us
                            </Link>
                        )}
                    </div>
                </div>
                <div className="col-lg-6 features">
                    <div>
                        <div className="card card-body">
                            <div>
                                <IconTailored className="injected-svg icon icon-lg bg-primary"/>
                            </div>
                            <p className="lead">
                            Praesent blandit dolor.Praesent blandit dolor.Praesent blandit dolor.
                            </p>
                        </div>
                        <div className="card card-body">
                            <div>
                                <IconTailored className="injected-svg icon icon-lg bg-primary"/>
                            </div>
                            <p className="lead">
                            Praesent blandit dolor.Praesent blandit dolor.Praesent blandit dolor.
                            </p>
                        </div>
                        <div className="card card-body">
                            <div>
                                <IconSun className="injected-svg icon icon-lg bg-primary"/>
                            </div>
                            <p className="lead">
                                {!isMobile
                                    ? `IconTailored IconTailored IconTailored IconTailored IconTailored  `
                                    : ` IconTailored IconTailored IconTailored IconTailored `}
                            </p>
                        </div>
                    </div>

                    {isTablet && (
                        <div className="mt-4 text-center">
                            <Link to="/#" className="btn btn-lg btn-yellow">
                                Contact us
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </section>
);

export default FintechDesignPage;

export const query = graphql`query FintechDesignPage {
    imageSecondSection: file(relativePath: {eq: "photos/design-fintech-app.jpg"}) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    imageFinanceBoring: file(
        relativePath: {eq: "photos/invest2.jpg"}
    ) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    imageFinanceBoringMobile: file(
        relativePath: {eq: "photos/payment_solution_platform_design-mobile.jpg"}
    ) {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 800)
        }
    }
    imageDontBeLeftBehind: file(
        relativePath: {eq: "photos/Payment_Platform_B2B.jpg"}
    ) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    imageDontBeLeftBehindMobile: file(
        relativePath: {eq: "photos/Payment_Platform_B2B_mobile.jpg"}
    ) {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 800)
        }
    }
}
`;
